<template>
  <v-container>
    <v-row>
      <v-col class="text-h5 text-center">
        {{ $trans("cancel_plan_generic_title") }}
      </v-col>
    </v-row>
    <v-row v-if="!showCancelOptions">
      <v-col cols="12">
        <calendesk-information-message color="snow_gray">
          <div class="text-center">
            <div v-if="onTrial">
              {{ $trans("cancel_subscription_title_2") }}
            </div>
            <div>
              {{ $trans("cancel_plan_generic_info") }}
            </div>
          </div>
        </calendesk-information-message>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="!showCancelOptions"
        cols="12"
        class="d-flex align-center justify-center flex-wrap"
      >
        <v-btn color="blue" outlined class="ma-2" @click="handleMeetUsClick">
          {{ $trans("meet_us") }}
        </v-btn>
        <v-btn color="blue" outlined class="ma-2" @click="handleOpenChatClick">
          {{ $trans("wb_chat_with_us") }}
        </v-btn>
        <v-btn
          v-if="!onTrial"
          color="light"
          outlined
          class="ma-2"
          @click="showCancelOptions = true"
        >
          <span class="accent_light--text">{{ $trans("cancel_plan_2") }}</span>
        </v-btn>
      </v-col>
      <template v-else-if="!onTrial && !isFetchingPlans">
        <v-col cols="12" class="text-h6 text-center">
          {{ $trans("cancel_subscription_title") }}
        </v-col>
        <v-col cols="12" :md="canShowMoveDownOption ? 6 : 12">
          <span class="text-h6">{{ $trans("cancel_plan") }}</span
          ><br />
          <span>{{ $trans("cancel_plan_1") }}</span>
          <v-alert
            color="snow_gray"
            class="mt-3 break-word-normal"
            min-height="200px"
          >
            <span class="dark--text">
              {{ $trans("cancel_subscription_text") }}<br />
              <span class="font-weight-bold">{{ currentPeriodEndsAt }}</span
              >.
              <br />
              <br />
              {{ $trans("cancel_subscription_text_2") }}
              <br />
              <br />
            </span>
            <v-btn
              color="light"
              outlined
              :loading="isCanceling"
              :disabled="isCanceling"
              @click="cancelSubscriptionClicked"
            >
              <span class="accent_light--text">{{
                $trans("cancel_plan_2")
              }}</span>
            </v-btn>
          </v-alert>
        </v-col>
        <v-col v-if="canShowMoveDownOption" cols="12" md="6">
          <span class="text-h6"
            >{{ $trans("cancel_subscription_subtitle_2") }} </span
          ><br />
          <span>{{ $trans("cancel_subscription_text_3") }}</span>
          <v-alert class="mt-3" color="snow_gray" min-height="200px">
            <span class="dark--text">
              {{
                $trans("cancel_subscription_text_4", {
                  plan: lowerPlan.name,
                  price: lowerPlanPrice,
                })
              }}
            </span>
            <br />
            <br />
            <v-btn color="blue" dark @click="lowerPlanClicked">
              {{ $trans("lower_plan") }}
            </v-btn>
          </v-alert>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import planActions from "@/calendesk/mixins/planActions";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import {
  handleMeetUsClick,
  handleOpenChatClick,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "CancelPlanDialog",
  components: { CalendeskInformationMessage },
  mixins: [planActions],
  data() {
    return {
      isCanceling: false,
      showCancelOptions: false,
    };
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    canShowMoveDownOption() {
      return (
        !this.onTrial &&
        this.currentPlan &&
        this.currentPlan.slug === this.$helpers.plans.CD_BASE_PRO
      );
    },
    lowerPlan() {
      return this.plans.find(
        (plan) => plan.slug === this.$helpers.plans.CD_BASE_STANDARD
      );
    },
    lowerPlanPrice() {
      return this.$options.filters.money(
        this.lowerPlanPeriod.price.amount,
        this.adminConfiguration ? this.adminConfiguration.tenant_currency : null
      );
    },
    lowerPlanPeriod() {
      return this.lowerPlan.periods.find(
        (period) => period.period === this.currentPlan.period
      );
    },
  },
  methods: {
    ...mapActions({
      cancelSubscription: "payments/cancelSubscription",
    }),
    cancelSubscriptionClicked() {
      pushEvent("cancelPlanLastQuestion");

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("cancel_plan_confirm_title"),
        warning: this.$trans("cancel_plan_confirm_text"),
        cancelText: this.$trans("cancel_plan_confirm_button_1"),
        confirmationText: this.$trans("cancel_plan_confirm_button_2"),
        hideImportantInformation: true,
        confirmationButtonColor: "red",
        cancelButtonColor: "green",
        confirmAction: () => {
          this.setCommonDialogLoader(true);

          pushEvent("cancelPlanConfirmation");

          this.cancelSubscription()
            .then(() => {
              this.fetchRequiredAppComponents()
                .then(() => {
                  successNotification("subscription_cancelled_successfully");
                  this.closeDialog();
                  this.closeConfirmDialog();
                })
                .finally(() => {
                  this.isCanceling = false;
                  this.setCommonDialogLoader(false);
                });
            })
            .catch((error) => {
              this.setCommonDialogLoader(false);
              errorNotification("error_occurred", error);
            });
        },
      });
    },
    lowerPlanClicked() {
      pushEvent("cancelPlanLowerPlan");

      this.$emit("close");
      this.$root.$emit("openPlanView", false);
    },
    handleMeetUsClick,
    handleOpenChatClick,
  },
};
</script>
